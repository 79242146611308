import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { withStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ButtonGroup from '@mui/material/ButtonGroup';
import InputAdornment from '@mui/material/InputAdornment';

//gambar
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PrintIcon from '@mui/icons-material/Print';
import { SaveOutlined } from '@mui/icons-material';
import RemoveIcon from '@mui/icons-material/Remove';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(248,246,255,255)",
            borderColor: "rgba(248,246,255,255)"
        },
    },
}))(TableRow);

function Pembayaran(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [data_order, setDataOrder] = useState([]);
    const [data_input_order, setDataInputOrder] = useState([]);
    const [data_metode_bayar, setDataMetodeBayar] = useState([]);
    const [data_pelanggan, setDataPelanggan] = useState([]);
    const [data_kendaraan, setDataKendaraan] = useState([]);

    const [id_order, setIdOrder] = useState("");
    const [status_pelanggan, setStatusPelanggan] = useState("Umum");
    const [pelanggan, setPelanggan] = useState(null);
    const [id_pelanggan, setIdPelanggan] = useState("");
    const [nama, setNama] = useState("");
    const [no_telpon, setNoTelpon] = useState("");
    const [no_kendaraan, setNoKendaraan] = useState("");
    const [kendaraan, setKendaraan] = useState(null);
    const [input_kendaraan, setInputKendaraan] = useState(null);

    const [id_bayar, setIdBayar] = useState("");
    const [metode_bayar, setMetodeBayar] = useState("M1");
    const [metode_bayar2, setMetodeBayar2] = useState("");
    const [jumlah_bayar, setJumlahBayar] = useState(0);
    const [jumlah_bayar2, setJumlahBayar2] = useState(0);
    const [metode_diskon, setMetodeDiskon] = useState("nominal");
    const [diskon_persen, setDiskonPersen] = useState(0);
    const [diskon, setDiskon] = useState(0);

    const [tampil_dialog_order, setTampilDialogOrder] = useState(false);
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);
    const [tampil_dialog_bayar, setTampilDialogBayar] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/kasir/tampil_order.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataOrder(data.data);
                }
                else {
                    setDataOrder([]);
                }
            })
            .catch(error => {
                setDataOrder([]);
            }
            );

        fetch(props.aplikasi + '/kasir/tampil_pelanggan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPelanggan(data.data);
                }
                else {
                    setDataPelanggan([]);
                }
            })
            .catch(error => {
                setDataPelanggan([]);
            }
            );

        fetch(props.aplikasi + '/kasir/tampil_kendaraan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataKendaraan(data.data);
                }
                else {
                    setDataKendaraan([]);
                }
            })
            .catch(error => {
                setDataKendaraan([]);
            }
            );

        fetch(props.aplikasi + '/metode_bayar/tampil_metode_bayar.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "ada"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetodeBayar(data.data);
                }
                else {
                    setDataMetodeBayar([]);
                }
            })
            .catch(error => {
                setDataMetodeBayar([]);
            }
            );

        const auto_refresh = setInterval(() => {
            fetch(props.aplikasi + '/kasir/tampil_order.php',
                {
                    method: 'GET',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataOrder(data.data);
                    }
                    else {
                        setDataOrder([]);
                    }
                })
                .catch(error => {
                    setDataOrder([]);
                }
                );
        }, 10000)

        return () => {
            clearInterval(auto_refresh);
        };

    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const format_tanggal = (waktu, format) => {
        let tahun = waktu.substring(0, 4);
        let bulan = waktu.substring(5, 7);
        let tanggal = waktu.substring(8, 10);
        let jam = waktu.substring(11, 13);
        let menit = waktu.substring(14, 16);
        let detik = waktu.substring(17, 19);

        if (format === "short") {
            return tanggal + "-" + bulan + "-" + tahun;
        }
        else {
            return tanggal + "-" + bulan + "-" + tahun + " " + jam + ":" + menit + ":" + detik;
        }
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const bersih_input_order = () => {
        setIdOrder("");
        setStatusPelanggan("Umum");
        setPelanggan(null);
        setIdPelanggan("");
        setNama("");
        setNoTelpon("");
        setNoKendaraan("");
        setKendaraan(null);
        setInputKendaraan("");
        setDataInputOrder([]);
    }

    const tampil_order = () => {
        setProses(true);
        fetch(props.aplikasi + '/kasir/tampil_order.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataOrder(data.data);
                    setProses(false);
                }
                else {
                    setDataOrder([]);
                    setProses(false);
                }
            })
            .catch(error => {
                setDataOrder([]);
                setProses(false);
            }
            );
    }

    const tambah_belanja = (id_layanan, id_kendaraan) => {
        let tampung = [...data_input_order];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan && tampung[awal - 1]["id_kendaraan"] === id_kendaraan) {
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                break;
            }
            awal++;
        }

        setDataInputOrder(tampung);
    }

    const kurang_belanja = (id_layanan, id_kendaraan) => {
        let tampung = [...data_input_order];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan && tampung[awal - 1]["id_kendaraan"] === id_kendaraan) {
                if (parseFloat(tampung[awal - 1]["jumlah"]) - 1 < 1) {
                    return;
                }
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) - 1;
                break;
            }
            awal++;
        }

        setDataInputOrder(tampung);
    }

    const ubah_jumlah_belanja = (event, id_layanan, id_kendaraan) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        let tampung = [...data_input_order];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan && tampung[awal - 1]["id_kendaraan"] === id_kendaraan) {
                tampung[awal - 1]["jumlah"] = event.target.value;
                break;
            }
            awal++;
        }

        setDataInputOrder(tampung);
    }

    const perbaiki_belanja = (event, id_layanan, id_kendaraan) => {
        let tampung = [...data_input_order];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan && tampung[awal - 1]["id_kendaraan"] === id_kendaraan) {
                if (parseFloat(event.target.value) > 0) {
                    tampung[awal - 1]["jumlah"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["jumlah"] = 1;
                }
                break;
            }
            awal++;
        }

        setDataInputOrder(tampung);
    }

    const hapus_belanja = (id_layanan, id_kendaraan) => {
        let tampung = [...data_input_order];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan && tampung[awal - 1]["id_kendaraan"] === id_kendaraan) {
                delete tampung[awal - 1];
                break;
            }
            awal++;
        }

        tampung = tampung.filter(function (element) {
            return element !== undefined;
        });

        setDataInputOrder(tampung);
    }

    const ubah_jumlah_bayar = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setJumlahBayar(event.target.value);
    }

    const perbaiki_jumlah_bayar = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setJumlahBayar(event.target.value);
        }
        else {
            setJumlahBayar(0);
        }
    }

    const ubah_jumlah_bayar2 = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setJumlahBayar2(event.target.value);
    }

    const perbaiki_jumlah_bayar2 = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setJumlahBayar2(event.target.value);
        }
        else {
            setJumlahBayar2(0);
        }
    }

    const ubah_diskon = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setDiskon(event.target.value);
    }

    const perbaiki_diskon = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setDiskon(event.target.value);
        }
        else {
            setDiskon(0);
        }
    }

    const ubah_diskon_persen = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setDiskonPersen(event.target.value);
        setDiskon(parseFloat((event.target.value / 100) * parseFloat(hitung_total())));
    }

    const perbaiki_diskon_persen = (event) => {
        if (event.target.value.trim() === "") {
            setDiskonPersen(0);
        }
    }

    const hitung_total = () => {
        let awal = 1;
        let akhir = data_input_order.length;
        let total = 0;
        while (awal <= akhir) {
            total = total + (parseFloat(data_input_order[awal - 1].biaya) * parseFloat(data_input_order[awal - 1].jumlah));
            awal++;
        }

        return total;
    }

    const simpan = () => {
        if (status_pelanggan === "Umum") {
            if (nama.trim() === "") {
                setJenisNotif("warning");
                setIsiNotif("Nama pelanggan wajib diisi");
                setTampilNotif(true);
                document.getElementById("nama").focus();
                return;
            }
        }
        else {
            if (pelanggan === null) {
                setJenisNotif("warning");
                setIsiNotif("Pelanggan belum dipilih");
                setTampilNotif(true);
                return;
            }
        }

        if (data_input_order.length === 0) {
            setJenisNotif("warning");
            setIsiNotif("Layanan belum dipilih");
            setTampilNotif(true);
            return;
        }

        setProses(true);
        fetch(props.aplikasi + '/kasir/simpan_order.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_order: id_order,
                        id_pelanggan: id_pelanggan,
                        nama: nama,
                        no_telpon: no_telpon,
                        no_kendaraan: no_kendaraan,
                        data_order: data_input_order,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_order();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                    tampil_order();
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const hapus = () => {
        setProses(true);
        fetch(props.aplikasi + '/kasir/hapus_order.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_order: id_order
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                    tampil_order();
                    setTampilDialogOrder(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const bayar = () => {
        if (metode_bayar.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Metode bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("metode_bayar").focus();
            return;
        }

        if (metode_bayar !== "M0" && parseFloat(jumlah_bayar) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Jumlah bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar").focus();
            return;
        }

        if ((metode_bayar2 !== "M0" && metode_bayar2 !== "") && jumlah_bayar2 === 0) {
            setJenisNotif("warning");
            setIsiNotif("Jumlah bayar Kedua wajib diisi");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar2").focus();
            return;
        }

        if ((metode_bayar !== "M0" && metode_bayar2 !== "M0") && metode_bayar2 !== "" && parseFloat(jumlah_bayar) + parseFloat(jumlah_bayar2) + parseFloat(diskon) - parseFloat(hitung_total()) !== 0) {
            setJenisNotif("warning");
            setIsiNotif("Bila menggunakan 2 metode pembayaran, uang harus pas");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar").focus();
            return;
        }

        setProses(true);
        fetch(props.aplikasi + '/kasir/simpan_pembayaran.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_order: id_order,
                        id_metode_bayar: metode_bayar,
                        id_metode_bayar2: metode_bayar2,
                        subtotal: hitung_total(),
                        diskon: diskon,
                        jumlah_bayar: jumlah_bayar,
                        jumlah_bayar2: jumlah_bayar2,
                        kembalian: parseFloat(jumlah_bayar) + parseFloat(jumlah_bayar2) - parseFloat(hitung_total()),
                        data_order: data_input_order,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setIdBayar(data.id_pembayaran);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                    tampil_order();
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Fab
                color="primary"
                sx={{
                    background: "#37ce9b",
                    border: "#37ce9b",
                    margin: 0,
                    top: "auto",
                    right: 20,
                    bottom: 75,
                    left: "auto",
                    position: "fixed",
                    zIndex: "99"
                }}
                onClick={() => {
                    bersih_input_order();
                    setTampilDialogOrder(true);
                }}
            >
                <AddIcon />
            </Fab>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_order.map((data_order) => {
                        return (
                            <Grid
                                item xs={6} sm={4} md={3} lg={2}
                                key={data_order.id_order}
                                onClick={() => {
                                    setIdOrder(data_order.id_order);

                                    if (data_order.id_pelanggan !== "") {
                                        setStatusPelanggan("Pelanggan");
                                        let awal = 1;
                                        let akhir = data_pelanggan.length;
                                        while (awal <= akhir) {
                                            if (data_order.id_pelanggan === data_pelanggan[awal - 1].id_pelanggan) {
                                                setPelanggan(data_pelanggan[awal - 1]);
                                            }
                                            awal++;
                                        }
                                    }
                                    else {
                                        setStatusPelanggan("Umum");
                                        setPelanggan(null);
                                    }

                                    setIdPelanggan(data_order.id_pelanggan);
                                    setNama(data_order.nama);
                                    setNoTelpon(data_order.no_telpon);
                                    setNoKendaraan(data_order.no_kendaraan);

                                    fetch(props.aplikasi + '/kasir/tampil_order_detail.php',
                                        {
                                            method: 'POST',
                                            body: JSON.stringify
                                                ({
                                                    id_order: data_order.id_order
                                                }),
                                            headers:
                                            {
                                                "Content-type": "application/json; charset=UTF-8"
                                            }
                                        })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.kode === 1) {
                                                setDataInputOrder(data.data);
                                            }
                                            else {
                                                setDataInputOrder([]);
                                            }
                                        })
                                        .catch(error => {
                                            setDataInputOrder([]);
                                        }
                                        );
                                    setTampilDialogOrder(true);
                                }}
                            >
                                <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                    <List>
                                        <ListItem button>
                                            <ListItemText
                                                primary={<Typography noWrap variant="subtitle1">{data_order.nama}</Typography>}
                                                secondary={<Typography noWrap variant="body2">{format_tanggal(data_order.waktu, "long")}</Typography>}
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog_order}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Input Order</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={5}>
                                <Grid container spacing={1}>
                                    <Grid container item>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Typography variant="body2">
                                                Status Pelanggan
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={8} md={9}>
                                            <RadioGroup
                                                row
                                                value={status_pelanggan}
                                                onChange={(event) => {
                                                    setStatusPelanggan(event.target.value);
                                                    if (event.target.value === "Umum") {
                                                        setIdPelanggan("");
                                                        setNama("");
                                                        setNoTelpon("");
                                                        setNoKendaraan("");
                                                    }
                                                }}
                                            >
                                                <FormControlLabel value="Umum" control={<Radio />} label="Umum" />
                                                <FormControlLabel value="Pelanggan" control={<Radio />} label="Pelanggan" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>

                                    {status_pelanggan === "Pelanggan" ?
                                        <>
                                            <Grid container item>
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <Typography variant="body2">
                                                        Pelanggan
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={8} md={9}>
                                                    <Autocomplete
                                                        options={data_pelanggan}
                                                        getOptionLabel={
                                                            (option) => option.nama + " (" + option.no_telpon + ")"
                                                        }
                                                        value={pelanggan}
                                                        onChange={(e, nilai) => {
                                                            setPelanggan(nilai);
                                                            if (nilai !== null) {
                                                                setIdPelanggan(nilai.id_pelanggan);
                                                                setNama(nilai.nama);
                                                                setNoTelpon(nilai.no_telpon);
                                                                setNoKendaraan(nilai.no_kendaraan);
                                                            }
                                                            else {
                                                                setIdPelanggan("");
                                                                setNama("");
                                                                setNoTelpon("");
                                                                setNoKendaraan("");
                                                            }
                                                        }}
                                                        renderInput={
                                                            (params) =>
                                                                <TextField
                                                                    {...params}
                                                                    key={params.id_pelanggan}
                                                                    variant="outlined"
                                                                    size="small"
                                                                />
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                        :
                                        <></>
                                    }

                                    {status_pelanggan === "Umum" ?
                                        <>
                                            <Grid container item>
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <Typography variant="body2">
                                                        Nama Pelanggan
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={8} md={9}>
                                                    <TextField
                                                        id="jumlah_bayar"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        value={nama}
                                                        onChange={(event) => setNama(event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container item>
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <Typography variant="body2">
                                                        No. Telpon
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={8} md={9}>
                                                    <TextField
                                                        id="no_telpon"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        value={no_telpon}
                                                        onChange={(event) => setNoTelpon(event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                        :
                                        <></>
                                    }

                                    <Grid container item>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Typography variant="body2">
                                                No. Kendaraan
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={8} md={9}>
                                            <TextField
                                                id="no_kendaraan"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={no_kendaraan}
                                                onChange={(event) => setNoKendaraan(event.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} md={7}>
                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Pilih Layanan
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <Autocomplete
                                            options={data_kendaraan}
                                            getOptionLabel={
                                                (option) => option.merk + " " + option.tipe + " (" + option.layanan + ") - " + format_rupiah(parseFloat(option.biaya))
                                            }
                                            // inputValue={input_kendaraan}
                                            value={kendaraan}
                                            // onInputChange={(e, nilai) => setInputKendaraan(nilai)}
                                            onChange={(e, nilai) => setKendaraan(nilai)}
                                            renderInput={
                                                (params) =>
                                                    <TextField
                                                        {...params}
                                                        key={params.id_kendaraan}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="flex-end" item>
                                            <LoadingButton
                                                loadingPosition="start"
                                                loading={proses}
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddIcon />}
                                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#37ce9b" }}
                                                onClick={() => {
                                                    if (kendaraan === null) {
                                                        setJenisNotif("warning");
                                                        setIsiNotif("Layanan belum dipilih");
                                                        setTampilNotif(true);
                                                        return;
                                                    }

                                                    let tampung = [...data_input_order];
                                                    let awal = 1;
                                                    let akhir = tampung.length;
                                                    let cari = "";
                                                    while (awal <= akhir) {
                                                        if (tampung[awal - 1]["id_layanan"] === kendaraan.id_layanan && tampung[awal - 1]["id_kendaraan"] === kendaraan.id_kendaraan) {
                                                            tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                                                            cari = "ada";
                                                            break;
                                                        }
                                                        awal++;
                                                    }

                                                    if (cari === "") {
                                                        tampung.push(kendaraan);
                                                    }

                                                    setDataInputOrder(tampung);
                                                    setInputKendaraan("");
                                                    setKendaraan(null);
                                                }}
                                            >
                                                Tambahkan
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <br /><br />

                                <TableContainer component={Paper}>
                                    <Table stickyHeader size="small" aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center">Jenis Layanan</StyledTableCell>
                                                <StyledTableCell align="center">Kendaraan</StyledTableCell>
                                                <StyledTableCell align="center">Biaya</StyledTableCell>
                                                <StyledTableCell align="center">Jumlah</StyledTableCell>
                                                <StyledTableCell align="center">Subtotal</StyledTableCell>
                                                <StyledTableCell align="center">Aksi</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data_input_order.map((tampung, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung.layanan}</Typography></TableCell>
                                                        <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung.merk + " " + tampung.tipe}</Typography></TableCell>
                                                        <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{format_rupiah(parseFloat(tampung.biaya))}</Typography></TableCell>
                                                        <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                            <Fab
                                                                color="primary"
                                                                size="small"
                                                                style={{ backgroundColor: "gray" }}
                                                                onClick={() => kurang_belanja(tampung.id_layanan, tampung.id_kendaraan)}
                                                            >
                                                                <RemoveIcon />
                                                            </Fab>
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                inputProps={{
                                                                    style: { textAlign: "center", width: "50px" }
                                                                }}
                                                                value={tampung.jumlah}
                                                                onChange={(e) => ubah_jumlah_belanja(e, tampung.id_layanan, tampung.id_kendaraan)}
                                                                onBlur={(e) => perbaiki_belanja(e, tampung.id_layanan, tampung.id_kendaraan)}
                                                            />
                                                            <Fab
                                                                color="primary"
                                                                size="small"
                                                                style={{ backgroundColor: "gray" }}
                                                                onClick={() => tambah_belanja(tampung.id_layanan, tampung.id_kendaraan)}
                                                            >
                                                                <AddIcon />
                                                            </Fab>
                                                        </TableCell>
                                                        <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{format_rupiah(parseFloat(tampung.biaya) * parseFloat(tampung.jumlah))}</Typography></TableCell>
                                                        <TableCell size="small" align="center">
                                                            <Fab
                                                                color="primary"
                                                                size="small"
                                                                style={{ backgroundColor: "red" }}
                                                                onClick={() => hapus_belanja(tampung.id_layanan, tampung.id_kendaraan)}
                                                            >
                                                                <DeleteIcon />
                                                            </Fab>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}

                                            <TableRow>
                                                <TableCell align="right" colSpan={4}>
                                                    <Typography variant="h6">
                                                        Total
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="h6" noWrap={true} sx={{ color: "red" }}>
                                                        {format_rupiah(parseFloat(hitung_total()))}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    {id_order !== "" ?
                        <>
                            <LoadingButton
                                loadingPosition="start"
                                loading={proses}
                                variant="contained"
                                color="primary"
                                startIcon={<AttachMoneyIcon />}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#37ce9b" }}
                                onClick={() => {
                                    setMetodeBayar("M1");
                                    setJumlahBayar(0);
                                    setMetodeBayar2("");
                                    setJumlahBayar2(0);
                                    setTampilDialogBayar(true)
                                }}
                            >
                                Bayar
                            </LoadingButton>
                            <LoadingButton
                                loadingPosition="start"
                                loading={proses}
                                variant="contained"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                                onClick={() => setTampilDialogHapus(true)}
                            >
                                Hapus
                            </LoadingButton>
                        </>
                        :
                        <></>
                    }

                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={
                            id_bayar === ""
                                ? { marginRight: "5px", marginTop: "5px", borderRadius: "5em" }
                                : { display: "none" }
                        }
                        onClick={() => simpan()}
                    >
                        {id_order === "" ? "Simpan" : "Perbarui"}
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        variant="contained"
                        color="primary"
                        startIcon={<CloseIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => {
                            setTampilDialogOrder(false);
                        }}
                    >
                        Tutup
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Order
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogHapus(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(false);
                            hapus();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_bayar}
            >
                <DialogTitle>Pembayaran</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Tagihan
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseFloat(hitung_total()))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Metode Bayar 1
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                id="metode_bayar"
                                                variant="outlined"
                                                fullWidth
                                                value={metode_bayar}
                                                onChange={(event) => {
                                                    if (event.target.value === metode_bayar2) {
                                                        setJenisNotif("warning");
                                                        setIsiNotif("Metode bayar harus berbeda");
                                                        setTampilNotif(true);
                                                        return;
                                                    }
                                                    else {
                                                        setMetodeBayar(event.target.value);
                                                        if (event.target.value === "M0") {
                                                            setJumlahBayar(0);
                                                        }
                                                    }
                                                }}
                                            >
                                                {data_metode_bayar.map((data_metode_bayar) => {
                                                    return (
                                                        <MenuItem key={data_metode_bayar.id_metode} value={data_metode_bayar.id_metode}>{data_metode_bayar.nama}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Jumlah Bayar 1
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="jumlah_bayar"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            disabled={metode_bayar === "M0" ? true : false}
                                            value={jumlah_bayar}
                                            onChange={(e) => ubah_jumlah_bayar(e)}
                                            onBlur={(e) => perbaiki_jumlah_bayar(e)}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Metode Bayar 2
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                id="metode_bayar2"
                                                variant="outlined"
                                                fullWidth
                                                value={metode_bayar2}
                                                onChange={(event) => {
                                                    if (event.target.value === metode_bayar) {
                                                        setJenisNotif("warning");
                                                        setIsiNotif("Metode bayar harus berbeda");
                                                        setTampilNotif(true);
                                                        return;
                                                    }
                                                    else {
                                                        setMetodeBayar2(event.target.value);
                                                        if (event.target.value === "M0") {
                                                            setJumlahBayar2(0);
                                                        }
                                                        else if (event.target.value === "") {
                                                            setJumlahBayar2(0);
                                                        }
                                                    }
                                                }}
                                            >
                                                <MenuItem value="">Pilih Metode Bayar Kedua</MenuItem>
                                                {data_metode_bayar.map((data_metode_bayar) => {
                                                    return (
                                                        <MenuItem key={data_metode_bayar.id_metode} value={data_metode_bayar.id_metode}>{data_metode_bayar.nama}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Jumlah Bayar 2
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="jumlah_bayar2"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            disabled={metode_bayar2 === "M0" ? true : false}
                                            value={jumlah_bayar2}
                                            onChange={(e) => ubah_jumlah_bayar2(e)}
                                            onBlur={(e) => perbaiki_jumlah_bayar2(e)}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar2))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Diskon
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid container spacing={1} item xs={6}>
                                        <Grid
                                            item xs={12}
                                        >
                                            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                                <LoadingButton
                                                    loadingPosition="start"
                                                    loading={proses}
                                                    variant={metode_diskon === "nominal" ? "contained" : "outlined"}
                                                    onClick={() => {
                                                        setMetodeDiskon("nominal");
                                                        setDiskon(0);
                                                        setDiskonPersen(0);
                                                    }}
                                                >
                                                    Rp.
                                                </LoadingButton>
                                                <LoadingButton
                                                    loadingPosition="start"
                                                    loading={proses}
                                                    variant={metode_diskon !== "nominal" ? "contained" : "outlined"}
                                                    onClick={() => {
                                                        setMetodeDiskon("persen");
                                                        setDiskon(0);
                                                        setDiskonPersen(0);
                                                    }}
                                                >
                                                    %
                                                </LoadingButton>
                                            </ButtonGroup>
                                        </Grid>

                                        <Grid
                                            item xs={12}
                                            sx={
                                                metode_diskon === "nominal" ? { display: "none" } : { display: "" }
                                            }
                                        >
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                multiline
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={diskon_persen}
                                                onChange={(e) => ubah_diskon_persen(e)}
                                                onBlur={(e) => perbaiki_diskon_persen(e)}
                                            />
                                        </Grid>

                                        <Grid
                                            item xs={12}
                                        >
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                multiline
                                                disabled={metode_diskon === "nominal" ? false : true}
                                                value={diskon}
                                                onChange={(e) => ubah_diskon(e)}
                                                onBlur={(e) => perbaiki_diskon(e)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={6}>
                                        <Grid
                                            item xs={12}
                                        >
                                            <Typography variant="h6" align="right" sx={{ color: "white" }}>
                                                Rp. {format_rupiah(parseFloat(diskon))}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item xs={12}
                                        >
                                            <Typography variant="h6" align="right" sx={{ color: "white" }}>
                                                Rp. {format_rupiah(parseFloat(diskon))}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item xs={12}
                                        >
                                            <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                                Rp. {format_rupiah(parseFloat(diskon))}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Kembalian
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar) + parseFloat(jumlah_bayar2) + parseFloat(diskon) - parseFloat(hitung_total()))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    {id_bayar !== "" ?
                        <LoadingButton
                            loadingPosition="start"
                            loading={proses}
                            variant="contained"
                            color="primary"
                            startIcon={<PrintIcon />}
                            sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                            onClick={() =>
                                window.open(props.aplikasi + '/kasir/cetak_pembayaran.php?id_bayar=' + id_bayar)
                            }
                        >
                            Cetak
                        </LoadingButton>
                        :
                        <></>
                    }

                    {id_bayar === "" ?
                        <LoadingButton
                            loadingPosition="start"
                            loading={proses}
                            variant="contained"
                            color="primary"
                            startIcon={<AttachMoneyIcon />}
                            sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                            onClick={() => bayar()}
                        >
                            Bayar
                        </LoadingButton>
                        :
                        <></>
                    }

                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        variant="contained"
                        color="primary"
                        startIcon={<CloseIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => {
                            setTampilDialogBayar(false);
                            if (id_bayar !== "") {
                                setIdBayar("");
                                setTampilDialogOrder(false);
                            }
                        }}
                    >
                        Tutup
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Pembayaran);