import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { withStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//gambar
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(248,246,255,255)",
            borderColor: "rgba(248,246,255,255)"
        },
    },
}))(TableRow);

function Riwayat_Transaksi(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [periode1, setPeriode1] = useState(new Date());
    const [periode2, setPeriode2] = useState(new Date());
    const [data_order, setDataOrder] = useState([]);
    const [data_input_order, setDataInputOrder] = useState([]);
    const [data_metode_bayar, setDataMetodeBayar] = useState([]);
    const [metode_bayar_sekarang, setMetodeBayarSekarang] = useState("");
    const [nama_metode_bayar_sekarang, setNamaMetodeBayarSekarang] = useState("");
    const [status_ubah, setStatusUbah] = useState("");

    const [status_pelanggan, setStatusPelanggan] = useState("");
    const [nama, setNama] = useState("");
    const [no_telpon, setNoTelpon] = useState("");
    const [no_kendaraan, setNoKendaraan] = useState("");

    const [id_bayar, setIdBayar] = useState("");
    const [waktu_pembayaran, setWaktuPembayaran] = useState("");
    const [metode_bayar, setMetodeBayar] = useState("");
    const [nama_metode_bayar, setNamaMetodeBayar] = useState("");
    const [metode_bayar2, setMetodeBayar2] = useState("");
    const [nama_metode_bayar2, setNamaMetodeBayar2] = useState("");
    const [jumlah_bayar, setJumlahBayar] = useState(0);
    const [jumlah_bayar2, setJumlahBayar2] = useState(0);
    const [kembalian, setKembalian] = useState(0);
    const [kasir, setKasir] = useState("");

    const [tampil_dialog, setTampilDialog] = useState(false);
    const [tampil_dialog_ubah, setTampilDialogUbah] = useState(false);
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);
    const [tampil_dialog_pembatalan, setTampilDialogPembatalan] = useState(false);

    //fungsi    
    useEffect(() => {
        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        fetch(props.aplikasi + '/kasir/tampil_riwayat.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        periode1: format_periode1,
                        periode2: format_periode2
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataOrder(data.data);
                }
                else {
                    setDataOrder([]);
                }
            })
            .catch(error => {
                setDataOrder([]);
            }
            );

        fetch(props.aplikasi + '/metode_bayar/tampil_metode_bayar.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "ada"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetodeBayar(data.data);
                }
                else {
                    setDataMetodeBayar([]);
                }
            })
            .catch(error => {
                setDataMetodeBayar([]);
            }
            );

        const auto_refresh = setInterval(() => {
            var tahun = String(periode1.getFullYear());
            var bulan = String(periode1.getMonth() + 1);
            if (bulan.length === 1) {
                bulan = "0" + bulan;
            }
            var tanggal = String(periode1.getDate());
            if (tanggal.length === 1) {
                tanggal = "0" + tanggal;
            }

            var format_periode1 = tahun + "-" + bulan + "-" + tanggal;
            tahun = String(periode2.getFullYear());
            bulan = String(periode2.getMonth() + 1);
            if (bulan.length === 1) {
                bulan = "0" + bulan;
            }
            tanggal = String(periode2.getDate());
            if (tanggal.length === 1) {
                tanggal = "0" + tanggal;
            }

            var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

            fetch(props.aplikasi + '/kasir/tampil_riwayat.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            id_pengguna: localStorage.getItem("id_pengguna"),
                            periode1: format_periode1,
                            periode2: format_periode2
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataOrder(data.data);
                    }
                    else {
                        setDataOrder([]);
                    }
                })
                .catch(error => {
                    setDataOrder([]);
                }
                );
        }, 10000);

        return () => {
            clearInterval(auto_refresh);
        };

    }, [props.aplikasi, periode1, periode2]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const format_tanggal = (waktu, format) => {
        let tahun = waktu.substring(0, 4);
        let bulan = waktu.substring(5, 7);
        let tanggal = waktu.substring(8, 10);
        let jam = waktu.substring(11, 13);
        let menit = waktu.substring(14, 16);
        let detik = waktu.substring(17, 19);

        if (format === "short") {
            return tanggal + "-" + bulan + "-" + tahun;
        }
        else {
            return tanggal + "-" + bulan + "-" + tahun + " " + jam + ":" + menit + ":" + detik;
        }
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const switch_status_ubah = (param) => {
        switch (param) {
            case "N":
                return "Tidak Ada Permintaan";
            case "R":
                return "Menunggu Diterima";
            case "A":
                return "Diterima";
            case "D":
                return "Ditolak";
            default:
                break;
        }
    }

    const switch_warna_status_ubah = (param) => {
        switch (param) {
            case "N":
                return "red";
            case "R":
                return "gold";
            case "A":
                return "green";
            case "D":
                return "brown";
            default:
                break;
        }
    }

    const hitung_total = () => {
        let awal = 1;
        let akhir = data_input_order.length;
        let total = 0;
        while (awal <= akhir) {
            total = total + (parseFloat(data_input_order[awal - 1].biaya) * parseFloat(data_input_order[awal - 1].jumlah));
            awal++;
        }

        return total;
    }

    const simpan = () => {
        if (metode_bayar.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Metode bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("metode_bayar").focus();
            return;
        }

        fetch(props.aplikasi + '/kasir/simpan_ubah_metode_bayar.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pembayaran: id_bayar,
                        metode_sekarang: metode_bayar_sekarang,
                        metode_baru: metode_bayar,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setStatusUbah("R");
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    const hapus = () => {
        fetch(props.aplikasi + '/kasir/hapus_permintaan_ubah.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pembayaran: id_bayar
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setTampilDialogUbah(false);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    const simpan_pembatalan = () => {
        fetch(props.aplikasi + '/kasir/simpan_pembatalan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pembayaran: id_bayar,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Tanggal Transaksi
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode1}
                                        onChange={(newValue) => {
                                            setPeriode1(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                                <Typography variant="body2" sx={{ marginLeft: "10px", marginRight: "10px" }}>
                                    s/d
                                </Typography>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode2}
                                        onChange={(newValue) => {
                                            setPeriode2(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_order.map((data_order) => {
                        return (
                            <Grid
                                item xs={6} sm={4} md={3} lg={2}
                                key={data_order.id_pesanan}
                                onClick={() => {
                                    if (data_order.id_pelanggan !== "") {
                                        setStatusPelanggan("Pelanggan");
                                    }
                                    else {
                                        setStatusPelanggan("Umum");
                                    }
                                    setNama(data_order.nama);
                                    setNoTelpon(data_order.no_telpon);
                                    setNoKendaraan(data_order.no_kendaraan);

                                    setIdBayar(data_order.id_pembayaran);
                                    setWaktuPembayaran(data_order.waktu_pembayaran);
                                    setMetodeBayar(data_order.id_metode_bayar);
                                    setNamaMetodeBayar(data_order.metode_bayar);
                                    setMetodeBayar2(data_order.id_metode_bayar2);
                                    setNamaMetodeBayar2(data_order.metode_bayar2);
                                    setJumlahBayar(data_order.jumlah_bayar);
                                    setJumlahBayar2(data_order.jumlah_bayar2);
                                    setJumlahBayar2(data_order.jumlah_bayar2);
                                    setKembalian(data_order.kembalian);
                                    setKasir(data_order.kasir);

                                    fetch(props.aplikasi + '/kasir/tampil_order_detail.php',
                                        {
                                            method: 'POST',
                                            body: JSON.stringify
                                                ({
                                                    id_order: data_order.id_order
                                                }),
                                            headers:
                                            {
                                                "Content-type": "application/json; charset=UTF-8"
                                            }
                                        })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.kode === 1) {
                                                setDataInputOrder(data.data);
                                            }
                                            else {
                                                setDataInputOrder([]);
                                            }
                                        })
                                        .catch(error => {
                                            setDataInputOrder([]);
                                        }
                                        );
                                    setTampilDialog(true);
                                }}
                            >
                                <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                    <List>
                                        <ListItem button>
                                            <ListItemText
                                                primary={<Typography noWrap variant="subtitle1">{data_order.nama}</Typography>}
                                                secondary={
                                                    <div>
                                                        <Typography noWrap variant="body2" color="white">
                                                            {data_order.waktu_pembayaran}
                                                        </Typography>
                                                    </div>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                fullScreen
                open={tampil_dialog}
            >
                <Box>
                    <AppBar
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Toolbar
                            sx={{ backgroundColor: "#95BDFF" }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setTampilDialog(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Detail Transaksi
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ marginTop: "64px", padding: "10px" }}>
                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h6">
                                Data Pelanggan
                            </Typography>
                            <Divider />
                            <br />

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        No. Bukti
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {id_bayar}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Waktu Pembayaran
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {format_tanggal(waktu_pembayaran, "long")}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Nama
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {nama} {no_telpon !== "" && no_telpon !== null ? "/ " + no_telpon : ""} {no_kendaraan !== "" && no_kendaraan !== null ? "/ " + no_kendaraan : ""} ({status_pelanggan})
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Metode Bayar
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {nama_metode_bayar + " (" + format_rupiah(parseFloat(jumlah_bayar)) + ")"} {metode_bayar2 !== "" ? " & " + nama_metode_bayar2 + " (" + format_rupiah(parseFloat(jumlah_bayar2)) + ")" : ""}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Kembalian
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        Rp. {format_rupiah(parseFloat(kembalian))}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Kasir
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {kasir}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>

                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h6">
                                Daftar Pesanan
                            </Typography>
                            <Divider />
                            <br />

                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PrintIcon />}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                onClick={() =>
                                    window.open(props.aplikasi + '/kasir/cetak_pembayaran.php?id_bayar=' + id_bayar)
                                }
                            >
                                Cetak
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<EditIcon />}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "tomato" }}
                                onClick={() => {
                                    fetch(props.aplikasi + '/kasir/tampil_ubah_metode_bayar.php',
                                        {
                                            method: 'POST',
                                            body: JSON.stringify
                                                ({
                                                    id_pembayaran: id_bayar
                                                }),
                                            headers:
                                            {
                                                "Content-type": "application/json; charset=UTF-8"
                                            }
                                        })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.kode === 1) {
                                                setMetodeBayarSekarang(data.data[0].metode_bayar);
                                                setNamaMetodeBayarSekarang(data.data[0].nama_metode_bayar);
                                                setMetodeBayar(data.data[0].metode_baru);
                                                setStatusUbah(data.data[0].status_ubah);
                                            }
                                            else {
                                                setMetodeBayarSekarang("");
                                                setNamaMetodeBayarSekarang("");
                                                setMetodeBayar("");
                                                setStatusUbah("N");
                                            }
                                        })
                                        .catch(error => {
                                            setMetodeBayarSekarang("");
                                            setNamaMetodeBayarSekarang("");
                                            setMetodeBayar("");
                                            setStatusUbah("N");
                                        }
                                        );

                                    setTampilDialogUbah(true);
                                }}
                            >
                                Ubah Cara Bayar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                                onClick={() => {
                                    setTampilDialogPembatalan(true);
                                }}
                            >
                                Ajukan Pembatalan
                            </Button>
                            <br /><br />

                            <TableContainer component={Paper}>
                                <Table stickyHeader size="small" aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Jenis Layanan</StyledTableCell>
                                            <StyledTableCell align="center">Kendaraan</StyledTableCell>
                                            <StyledTableCell align="center">Biaya</StyledTableCell>
                                            <StyledTableCell align="center">Jumlah</StyledTableCell>
                                            <StyledTableCell align="center">Subtotal</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data_input_order.map((tampung, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung.layanan}</Typography></TableCell>
                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung.merk + " " + tampung.tipe}</Typography></TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{format_rupiah(parseFloat(tampung.biaya))}</Typography></TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{format_rupiah(parseFloat(tampung.jumlah))}</Typography></TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{format_rupiah(parseFloat(tampung.biaya) * parseFloat(tampung.jumlah))}</Typography></TableCell>
                                                </StyledTableRow>
                                            );
                                        })}

                                        <TableRow>
                                            <TableCell align="right" colSpan={4}>
                                                <Typography variant="h6">
                                                    Total
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="h6" noWrap={true} sx={{ color: "red" }}>
                                                    {format_rupiah(parseFloat(hitung_total()))}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                open={tampil_dialog_ubah}
                onClose={() => setTampilDialogUbah(false)}
            >
                <DialogTitle>Permintaan Pengubahan Metode Bayar</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5} sm={4} md={4}>
                                    <Typography variant="body2">
                                        Metode Saat Ini
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={8}>
                                    <Typography variant="body2">
                                        {nama_metode_bayar_sekarang}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={4}>
                                    <Typography variant="body2">
                                        Menjadi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={8}>
                                    <FormControl size="small" fullWidth>
                                        <Select
                                            id="metode_bayar"
                                            variant="outlined"
                                            fullWidth
                                            value={metode_bayar}
                                            onChange={(event) => {
                                                setMetodeBayar(event.target.value);
                                            }}
                                        >
                                            {data_metode_bayar.map((data_metode_bayar) => {
                                                return (
                                                    <MenuItem key={data_metode_bayar.id_metode} value={data_metode_bayar.id_metode}>{data_metode_bayar.nama}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={4}>
                                    <Typography variant="body2">
                                        Status Permintaan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={8}>
                                    <Typography variant="body2"
                                        sx={{
                                            color: switch_warna_status_ubah(status_ubah)
                                        }}
                                    >
                                        {switch_status_ubah(status_ubah)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>Simpan</Typography>
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        onClick={() => setTampilDialogHapus(true)}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogUbah(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Permintaan
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTampilDialogHapus(false)} autoFocus>Tidak</Button>
                    <Button
                        onClick={() => {
                            hapus();
                            setTampilDialogHapus(false);
                        }}
                    >Ya</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_pembatalan}
                onClose={() => setTampilDialogPembatalan(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Pembatalan Transaksi
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin membatalkan transaksi ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTampilDialogPembatalan(false)} autoFocus>Tidak</Button>
                    <Button
                        onClick={() => {
                            simpan_pembatalan();
                            setTampilDialogPembatalan(false);
                        }}
                    >Ya</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Riwayat_Transaksi);