import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

function Kendaraan(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [sumber_kendaraan, setSumberKendaraan] = useState([]);
    const [data_layanan, setDataLayanan] = useState([]);
    const [data_kendaraan, setDataKendaraan] = useState([]);
    const [id_kendaraan, setIdKendaraan] = useState("");
    const [layanan, setLayanan] = useState(null);
    const [merk, setMerk] = useState("");
    const [tipe, setTipe] = useState("");
    const [biaya, setBiaya] = useState(0);
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/kendaraan/tampil_layanan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataLayanan(data.data);
                }
                else {
                    setDataLayanan([]);
                }
            })
            .catch(error => {
                setDataLayanan([]);
            }
            );

        fetch(props.aplikasi + '/kendaraan/tampil_kendaraan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberKendaraan(data.data);
                    setDataKendaraan(data.data);
                }
                else {
                    setSumberKendaraan([]);
                    setDataKendaraan([]);
                }
            })
            .catch(error => {
                setSumberKendaraan([]);
                setDataKendaraan([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tampil_kendaraan = () => {
        fetch(props.aplikasi + '/kendaraan/tampil_kendaraan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberKendaraan(data.data);
                    setDataKendaraan(data.data);
                }
                else {
                    setSumberKendaraan([]);
                    setDataKendaraan([]);
                }
            })
            .catch(error => {
                setSumberKendaraan([]);
                setDataKendaraan([]);
            }
            );
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_kendaraan.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_kendaraan[awal - 1]["layanan"]).toLowerCase()).indexOf(cari);
            if (hasil === -1) {
                hasil = ((sumber_kendaraan[awal - 1]["merk"]).toLowerCase()).indexOf(cari);
            }

            if (hasil === -1) {
                hasil = ((sumber_kendaraan[awal - 1]["tipe"]).toLowerCase()).indexOf(cari);
            }

            if (hasil !== -1) {
                tampung[posisi] = sumber_kendaraan[awal - 1];
                posisi++;
            }
            awal++;
        }

        setDataKendaraan(tampung);
    }

    const bersih_input_kendaraan = () => {
        setIdKendaraan("");
        setLayanan(null);
        setMerk("");
        setTipe("");
        setBiaya(0);
        setStatus(false);
        setLabelStatus("Nonaktif");
    }

    const simpan = () => {
        if (layanan === null) {
            setJenisNotif("warning");
            setIsiNotif("Jenis layanan wajib diisi");
            setTampilNotif(true);
            document.getElementById("jenis").focus();
            return;
        }

        if (merk.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Merk wajib diisi");
            setTampilNotif(true);
            document.getElementById("merk").focus();
            return;
        }

        if (tipe.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Tipe wajib diisi");
            setTampilNotif(true);
            document.getElementById("tipe").focus();
            return;
        }

        setProses(true);
        if (id_kendaraan === "") {
            fetch(props.aplikasi + '/kendaraan/simpan_kendaraan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_kendaraan: "",
                            id_layanan: layanan.id_layanan,
                            merk: merk,
                            tipe: tipe,
                            biaya: biaya,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_kendaraan();
                        tampil_kendaraan();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("layanan").focus();
                        setProses(false);
                    }
                    else {
                        tampil_kendaraan();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("layanan").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_kendaraan();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("layanan").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/kendaraan/simpan_kendaraan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_kendaraan: id_kendaraan,
                            id_layanan: layanan.id_layanan,
                            merk: merk,
                            tipe: tipe,
                            biaya: biaya,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_kendaraan();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("layanan").focus();
                        setProses(false);
                    }
                    else {
                        tampil_kendaraan();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("layanan").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_kendaraan();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("layanan").focus();
                    setProses(false);
                }
                );
        }
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_kendaraan();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan jenis layanan, merk atau tipe kendaraan"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={cari}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_kendaraan.map((data_kendaraan) => {
                        return (
                            <Grid
                                item xs={6} md={3} lg={2}
                                key={data_kendaraan.id_kendaraan}
                                onClick={() => {
                                    bersih_input_kendaraan();
                                    setIdKendaraan(data_kendaraan.id_kendaraan);
                                    
                                    let awal = 1;
                                    let akhir = data_layanan.length;
                                    while (awal <= akhir){
                                        if (data_layanan[awal-1].id_layanan === data_kendaraan.id_layanan){
                                            setLayanan(data_layanan[awal-1]);
                                            break;
                                        }
                                        awal++;
                                    }

                                    setMerk(data_kendaraan.merk);
                                    setTipe(data_kendaraan.tipe);

                                    if (data_kendaraan.status === "1") {
                                        setStatus(true);
                                        setLabelStatus("Aktif");
                                    }
                                    else {
                                        setStatus(false);
                                        setLabelStatus("Nonaktif");
                                    }

                                    setTampilDialog(true);
                                }}
                            >
                                <Card>
                                    <List>
                                        <ListItem button alignItems="flex-start">
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1" noWrap>{data_kendaraan.merk + " " + data_kendaraan.tipe}</Typography>
                                                }
                                                secondary={
                                                    <div>
                                                        <Typography variant="subtitle1" noWrap>{data_kendaraan.layanan}</Typography>
                                                        <Typography variant="body2" noWrap>Rp. {format_rupiah(parseFloat(data_kendaraan.biaya))}</Typography>
                                                    </div>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Kendaraan</DialogTitle>

                <DialogContent>
                    <DialogContentText>

                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Jenis Layanan
                                    </Typography>
                                </Grid>

                                <Grid item xs={8} sm={8} md={9}>
                                    <Autocomplete
                                        id="layanan"
                                        options={data_layanan}
                                        getOptionLabel={(option) => option.nama}
                                        value={layanan}
                                        onChange={(e, nilai) => setLayanan(nilai)}
                                        renderInput={(params) => <TextField {...params} key={params.id_layanan} variant="outlined" size="small" />}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    
                                </Grid>

                                <Grid container item xs={7} sm={8} md={9}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {layanan !== null ? format_rupiah(parseFloat(layanan.biaya)) : 0}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Merk
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <TextField
                                        id="merk"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={merk}
                                        onChange={(event) => setMerk(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Tipe
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <TextField
                                        id="tipe"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={tipe}
                                        onChange={(event) => setTipe(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>{id_kendaraan === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Kendaraan);