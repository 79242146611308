import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

function Layanan(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [sumber_layanan, setSumberLayanan] = useState([]);
    const [data_layanan, setDataLayanan] = useState([]);
    const [id_layanan, setIdLayanan] = useState("");
    const [nama, setNama] = useState("");
    const [biaya, setBiaya] = useState(0);
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/layanan/tampil_layanan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberLayanan(data.data);
                    setDataLayanan(data.data);
                }
                else {
                    setSumberLayanan([]);
                    setDataLayanan([]);
                }
            })
            .catch(error => {
                setSumberLayanan([]);
                setDataLayanan([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tampil_layanan = () => {
        fetch(props.aplikasi + '/layanan/tampil_layanan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberLayanan(data.data);
                    setDataLayanan(data.data);
                }
                else {
                    setSumberLayanan([]);
                    setDataLayanan([]);
                }
            })
            .catch(error => {
                setSumberLayanan([]);
                setDataLayanan([]);
            }
            );
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_layanan.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_layanan[awal - 1]["nama"]).toLowerCase()).indexOf(cari);

            if (hasil !== -1) {
                tampung[posisi] = sumber_layanan[awal - 1];
                posisi++;
            }
            awal++;
        }

        setDataLayanan(tampung);
    }

    const bersih_input_layanan = () => {
        setIdLayanan("");
        setNama("");
        setBiaya(0);
        setStatus(false);
        setLabelStatus("Nonaktif");
    }

    const ubah_biaya = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setBiaya(event.target.value);
    }

    const perbaiki_biaya = (event) => {
        if (event.target.value.trim() === "") {
            setBiaya(0);
        }
    }

    const simpan = () => {
        if (nama.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        setProses(true);
        if (id_layanan === "") {
            fetch(props.aplikasi + '/layanan/simpan_layanan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_layanan: "",
                            nama: nama,
                            biaya: biaya,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_layanan();
                        tampil_layanan();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_layanan();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_layanan();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/layanan/simpan_layanan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_layanan: id_layanan,
                            nama: nama,
                            biaya: biaya,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_layanan();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_layanan();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_layanan();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_layanan();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nama layanan"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={cari}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_layanan.map((data_layanan) => {
                        return (
                            <Grid
                                item xs={6} md={3} lg={2}
                                key={data_layanan.id_layanan}
                                onClick={() => {
                                    bersih_input_layanan();
                                    setIdLayanan(data_layanan.id_layanan);
                                    setNama(data_layanan.nama);
                                    setBiaya(data_layanan.biaya);

                                    if (data_layanan.status === "1") {
                                        setStatus(true);
                                        setLabelStatus("Aktif");
                                    }
                                    else {
                                        setStatus(false);
                                        setLabelStatus("Nonaktif");
                                    }

                                    setTampilDialog(true);
                                }}
                            >
                                <Card>
                                    <List>
                                        <ListItem button alignItems="flex-start">
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1" noWrap>{data_layanan.nama}</Typography>
                                                }
                                                secondary={
                                                    <div>
                                                        <Typography variant="body2" noWrap>Rp. {format_rupiah(parseFloat(data_layanan.biaya))}</Typography>
                                                    </div>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Layanan</DialogTitle>

                <DialogContent>
                    <DialogContentText>

                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Nama Layanan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <TextField
                                        id="nama"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Biaya
                                    </Typography>
                                </Grid>

                                <Grid container item xs={7} sm={8} md={9}>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="biaya"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            value={biaya}
                                            onChange={(event) => ubah_biaya(event)}
                                            onBlur={(e) => perbaiki_biaya(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseInt(biaya))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>{id_layanan === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Layanan);