//komponen
import * as React from 'react';
import { Navigate } from 'react-router-dom';

//form
import UtamaAdmin from './Admin/utama';
import UtamaKasir from './Kasir/utama';

function Utama(props, savedChanges) {
    //fungsi    
    const keluar = () => {
        localStorage.clear();
    }

    if (!localStorage.getItem("login")) {
        return <Navigate to="/login" />;
    }
    else {
        if (localStorage.getItem("level") === "A") {
            return (
                <UtamaAdmin
                    aplikasi={props.aplikasi}
                    file={props.file}
                    basename={props.basename}
                    proses_keluar={() => keluar()}
                />
            )
        }
        else if (localStorage.getItem("level") === "K") {
            return (
                <UtamaKasir
                    aplikasi={props.aplikasi}
                    file={props.file}
                    basename={props.basename}
                    proses_keluar={() => keluar()}
                />
            )
        }
    }
}

export default Utama;