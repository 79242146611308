import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { withStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//gambar
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(248,246,255,255)",
            borderColor: "rgba(248,246,255,255)"
        },
    },
}))(TableRow);

function Piutang(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [data_order, setDataOrder] = useState([]);
    const [data_metode_bayar, setDataMetodeBayar] = useState([]);

    const [id_pembayaran, setIdPembayaran] = useState("");
    const [waktu_pembayaran, setWaktuPembayaran] = useState("");
    const [status_pelanggan, setStatusPelanggan] = useState("");
    const [nama, setNama] = useState("");
    const [no_telpon, setNoTelpon] = useState("");
    const [no_kendaraan, setNoKendaraan] = useState("");
    const [nama_metode_bayar, setNamaMetodeBayar] = useState("");
    const [nama_metode_bayar2, setNamaMetodeBayar2] = useState("");
    const [hutang_awal, setHutangAwal] = useState(0);
    const [angsuran, setAngsuran] = useState(0);
    const [kasir, setKasir] = useState("");

    const [data_angsuran, setDataAngsuran] = useState([]);
    const [metode_bayar, setMetodeBayar] = useState("M1");
    const [metode_bayar2, setMetodeBayar2] = useState("");
    const [jumlah_bayar, setJumlahBayar] = useState(0);
    const [jumlah_bayar2, setJumlahBayar2] = useState(0);
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [tampil_dialog_bayar, setTampilDialogBayar] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/kasir/tampil_piutang.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataOrder(data.data);
                }
                else {
                    setDataOrder([]);
                }
            })
            .catch(error => {
                setDataOrder([]);
            }
            );

        fetch(props.aplikasi + '/metode_bayar/tampil_metode_bayar.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "ada"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetodeBayar(data.data);
                }
                else {
                    setDataMetodeBayar([]);
                }
            })
            .catch(error => {
                setDataMetodeBayar([]);
            }
            );

        const auto_refresh = setInterval(() => {
            fetch(props.aplikasi + '/kasir/tampil_piutang.php',
                {
                    method: 'GET',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataOrder(data.data);
                    }
                    else {
                        setDataOrder([]);
                    }
                })
                .catch(error => {
                    setDataOrder([]);
                }
                );
        }, 10000)

        return () => {
            clearInterval(auto_refresh);
        };

    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const format_tanggal = (waktu, format) => {
        let tahun = waktu.substring(0, 4);
        let bulan = waktu.substring(5, 7);
        let tanggal = waktu.substring(8, 10);
        let jam = waktu.substring(11, 13);
        let menit = waktu.substring(14, 16);
        let detik = waktu.substring(17, 19);

        if (format === "short") {
            return tanggal + "-" + bulan + "-" + tahun;
        }
        else {
            return tanggal + "-" + bulan + "-" + tahun + " " + jam + ":" + menit + ":" + detik;
        }
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_pesanan = () => {
        fetch(props.aplikasi + '/kasir/tampil_piutang.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataOrder(data.data);
                    let awal = 1;
                    let akhir = data.data.length;
                    while (awal <= akhir) {
                        if (data.data[awal - 1].id_pembayaran === id_pembayaran) {
                            setAngsuran(data.data[awal - 1].angsuran);
                            break;
                        }
                        awal++;
                    }
                }
                else {
                    setDataOrder([]);
                }
            })
            .catch(error => {
                setDataOrder([]);
            }
            );
    }

    const ubah_jumlah_bayar = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setJumlahBayar(event.target.value);
    }

    const perbaiki_jumlah_bayar = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setJumlahBayar(event.target.value);
        }
        else {
            setJumlahBayar(0);
        }
    }

    const simpan = () => {
        if (parseFloat(hutang_awal) - parseFloat(angsuran) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Hutang sudah dilunasi");
            setTampilNotif(true);
            return;
        }

        if (metode_bayar.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Metode bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("metode_bayar").focus();
            return;
        }

        if (metode_bayar.trim() === "M0") {
            setJenisNotif("warning");
            setIsiNotif("Metode bayar ini tidak dapat digunakan");
            setTampilNotif(true);
            document.getElementById("metode_bayar").focus();
            return;
        }

        if (parseInt(jumlah_bayar) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Jumlah bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar").focus();
            return;
        }

        let tampung_jumlah_bayar = 0;
        if (jumlah_bayar > parseFloat(hutang_awal) - parseFloat(angsuran)) {
            tampung_jumlah_bayar = parseFloat(hutang_awal) - parseFloat(angsuran);
        }
        else {
            tampung_jumlah_bayar = jumlah_bayar;
        }

        fetch(props.aplikasi + '/kasir/simpan_piutang.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pembayaran: id_pembayaran,
                        id_metode_bayar: metode_bayar,
                        jumlah_bayar: tampung_jumlah_bayar,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    if (jumlah_bayar >= parseFloat(hutang_awal) - parseFloat(angsuran)) {
                        setAngsuran(hutang_awal);
                    }
                    else {
                        tampil_pesanan();
                    }

                    fetch(props.aplikasi + '/kasir/tampil_angsuran.php',
                        {
                            method: 'POST',
                            body: JSON.stringify
                                ({
                                    id_pembayaran: id_pembayaran
                                }),
                            headers:
                            {
                                "Content-type": "application/json; charset=UTF-8"
                            }
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data.kode === 1) {
                                setDataAngsuran(data.data);
                            }
                            else {
                                setDataAngsuran([]);
                            }
                        })
                        .catch(error => {
                            setDataAngsuran([]);
                        }
                        );

                    setTampilDialogBayar(false);
                    setTampilDialog(true);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_order.map((data_order) => {
                        return (
                            <Grid
                                item xs={6} sm={4} md={3} lg={2}
                                key={data_order.id_pembayaran}
                                onClick={() => {
                                    if (data_order.id_pelanggan !== "") {
                                        setStatusPelanggan("Pelanggan");
                                    }
                                    else {
                                        setStatusPelanggan("Umum");
                                    }
                                    setNama(data_order.nama);
                                    setNoTelpon(data_order.no_telpon);
                                    setNoKendaraan(data_order.no_kendaraan);

                                    setIdPembayaran(data_order.id_pembayaran);
                                    setWaktuPembayaran(data_order.waktu_pembayaran);
                                    setNamaMetodeBayar(data_order.metode_bayar);
                                    setMetodeBayar2(data_order.id_metode_bayar2);
                                    setNamaMetodeBayar2(data_order.metode_bayar2);
                                    setJumlahBayar(data_order.jumlah_bayar);
                                    setJumlahBayar2(data_order.jumlah_bayar2);
                                    setJumlahBayar2(data_order.jumlah_bayar2);
                                    setHutangAwal(data_order.hutang_awal);
                                    setAngsuran(data_order.angsuran);
                                    setKasir(data_order.kasir);
                                    fetch(props.aplikasi + '/kasir/tampil_angsuran.php',
                                        {
                                            method: 'POST',
                                            body: JSON.stringify
                                                ({
                                                    id_pembayaran: data_order.id_pembayaran
                                                }),
                                            headers:
                                            {
                                                "Content-type": "application/json; charset=UTF-8"
                                            }
                                        })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.kode === 1) {
                                                setDataAngsuran(data.data);
                                            }
                                            else {
                                                setDataAngsuran([]);
                                            }
                                        })
                                        .catch(error => {
                                            setDataAngsuran([]);
                                        }
                                        );

                                    setTampilDialog(true);
                                }}
                            >
                                <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                    <List>
                                        <ListItem button>
                                            <ListItemText
                                                primary={<Typography noWrap variant="subtitle1">{data_order.nama} {data_order.no_telpon !== "" && data_order.no_telpon !== null ? "/ " + data_order.no_telpon : ""}</Typography>}
                                                secondary={<Typography noWrap variant="body2">Rp. {format_rupiah(parseFloat(data_order.hutang_awal) - parseFloat(data_order.angsuran))}</Typography>}
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                fullScreen
                open={tampil_dialog}
            >
                <Box>
                    <AppBar
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Toolbar
                            sx={{ backgroundColor: "#95BDFF" }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setTampilDialog(false)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Detail Piutang
                            </Typography>
                            <Button autoFocus color="inherit"
                                onClick={() => {
                                    setJumlahBayar(0);
                                    setTampilDialogBayar(true)
                                }}
                            >
                                Bayar
                            </Button>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ marginTop: "64px", padding: "10px" }}>
                        <Card sx={{ padding: "10px" }}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        No. Bukti
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {id_pembayaran}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Waktu Pembayaran
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {format_tanggal(waktu_pembayaran, "long")}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Nama
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {nama} {no_telpon !== "" && no_telpon !== null ? "/ " + no_telpon : ""} {no_kendaraan !== "" && no_kendaraan !== null ? "/ " + no_kendaraan : ""} ({status_pelanggan})
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Metode Bayar
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {nama_metode_bayar + " (" + format_rupiah(parseFloat(jumlah_bayar)) + ")"} {metode_bayar2 !== "" ? " & " + nama_metode_bayar2 + " (" + format_rupiah(parseFloat(jumlah_bayar2)) + ")" : ""}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Kasir
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        {kasir}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Hutang Awal
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        Rp. {format_rupiah(parseFloat(hutang_awal))}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Sudah Diangsur
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        Rp. {format_rupiah(parseFloat(angsuran))}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Sisa Hutang
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">
                                        Rp. {format_rupiah(parseFloat(hutang_awal) - parseFloat(angsuran))}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Metode Bayar
                                    </Typography>
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={9}>
                                    <Grid item xs={6}>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                id="metode_bayar"
                                                variant="outlined"
                                                fullWidth
                                                value={metode_bayar}
                                                onChange={(event) => setMetodeBayar(event.target.value)}
                                            >
                                                {data_metode_bayar.map((data_metode_bayar) => {
                                                    return (
                                                        <MenuItem key={data_metode_bayar.id_metode} value={data_metode_bayar.id_metode}>{data_metode_bayar.nama}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>

                        <Card sx={{ padding: "10px" }}>
                            <Typography variant="h6">
                                Riwayat Angsuran
                            </Typography>
                            <Divider />
                            <br />

                            <TableContainer component={Paper}>
                                <Table stickyHeader size="small" aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Tanggal</StyledTableCell>
                                            <StyledTableCell align="center">Metode Bayar</StyledTableCell>
                                            <StyledTableCell align="center">Kasir</StyledTableCell>
                                            <StyledTableCell align="center">Jumlah</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data_angsuran.map((tampung) => {
                                            return (
                                                <StyledTableRow key={tampung.id_angsuran}>
                                                    <TableCell size="small" align="center"><Typography variant="body2" noWrap={true}>{tampung.waktu_angsuran}</Typography></TableCell>
                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung.metode_bayar}</Typography></TableCell>
                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung.kasir}</Typography></TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{format_rupiah(parseFloat(tampung.jumlah_bayar))}</Typography></TableCell>
                                                </StyledTableRow>
                                            );
                                        })}

                                        <TableRow>
                                            <TableCell align="right" colSpan={3}>
                                                <Typography variant="h6">
                                                    Total
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "red" }}>{format_rupiah(parseFloat(angsuran))}</Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                open={tampil_dialog_bayar}
            >
                <DialogTitle>Pembayaran</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Tagihan
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseFloat(hutang_awal) - parseFloat(angsuran))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Jumlah Bayar
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="jumlah_bayar"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            disabled={metode_bayar === "M0" ? true : false}
                                            value={jumlah_bayar}
                                            onChange={(e) => ubah_jumlah_bayar(e)}
                                            onBlur={(e) => perbaiki_jumlah_bayar(e)}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                            Rp. {format_rupiah(parseInt(jumlah_bayar))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" align="right">
                                            Kembalian
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar) - (parseFloat(hutang_awal) - parseFloat(angsuran)))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AttachMoneyIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        onClick={() => simpan()}
                    >
                        Bayar
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => {
                            setTampilDialogBayar(false);
                        }}
                    >
                        Tutup
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Piutang);