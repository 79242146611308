import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

function Pelanggan(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setNamaNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [sumber_pelanggan, setSumberPelanggan] = useState([]);
    const [data_pelanggan, setDataPelanggan] = useState([]);
    const [id_pelanggan, setIdPelanggan] = useState("");
    const [nama, setNama] = useState("");
    const [nik, setNik] = useState("");
    const [alamat, setAlamat] = useState("");
    const [pekerjaan, setPekerjaan] = useState("");
    const [no_telpon, setNoTelpon] = useState("");
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/pelanggan/tampil_pelanggan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberPelanggan(data.data);
                    setDataPelanggan(data.data);
                }
                else {
                    setSumberPelanggan([]);
                    setDataPelanggan([]);
                }
            })
            .catch(error => {
                setSumberPelanggan([]);
                setDataPelanggan([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_pelanggan = () => {
        fetch(props.aplikasi + '/pelanggan/tampil_pelanggan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberPelanggan(data.data);
                    setDataPelanggan(data.data);
                }
                else {
                    setSumberPelanggan([]);
                    setDataPelanggan([]);
                }
            })
            .catch(error => {
                setSumberPelanggan([]);
                setDataPelanggan([]);
            }
            );
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_pelanggan.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_pelanggan[awal - 1]["nama"]).toLowerCase()).indexOf(cari);

            if (hasil !== -1) {
                tampung[posisi] = sumber_pelanggan[awal - 1];
                posisi++;
            }
            awal++;
        }

        setDataPelanggan(tampung);
    }

    const bersih_input_pelanggan = () => {
        setIdPelanggan("");
        setNama("");
        setNik("");
        setAlamat("");
        setPekerjaan("");
        setNoTelpon("");
        setStatus(false);
        setLabelStatus("Nonaktif");
    }

    const simpan = () => {
        if (nama.trim() === "") {
            setNamaNotif("warning");
            setIsiNotif("Nama wajib diisi");
            setTampilNotif(true);
            document.getElementById("jenis").focus();
            return;
        }

        if (no_telpon.trim() === "") {
            setNamaNotif("warning");
            setIsiNotif("No. Telpon wajib diisi");
            setTampilNotif(true);
            document.getElementById("no_telpon").focus();
            return;
        }

        setProses(true);
        if (id_pelanggan === "") {
            fetch(props.aplikasi + '/pelanggan/simpan_pelanggan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_pelanggan: "",
                            nama: nama,
                            nik: nik,
                            alamat: alamat,
                            pekerjaan: pekerjaan,
                            no_telpon: no_telpon,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_pelanggan();
                        tampil_pelanggan();
                        setNamaNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_pelanggan();
                        setNamaNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_pelanggan();
                    setNamaNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/pelanggan/simpan_pelanggan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_pelanggan: id_pelanggan,
                            nama: nama,
                            nik: nik,
                            alamat: alamat,
                            pekerjaan: pekerjaan,
                            no_telpon: no_telpon,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_pelanggan();
                        setNamaNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_pelanggan();
                        setNamaNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_pelanggan();
                    setNamaNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_pelanggan();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nama pelanggan"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={cari}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_pelanggan.map((data_pelanggan) => {
                        return (
                            <Grid
                                item xs={6} md={3} lg={2}
                                key={data_pelanggan.id_pelanggan}
                                onClick={() => {
                                    bersih_input_pelanggan();
                                    setIdPelanggan(data_pelanggan.id_pelanggan);
                                    setNama(data_pelanggan.nama);
                                    setNik(data_pelanggan.nik);
                                    setAlamat(data_pelanggan.alamat);
                                    setPekerjaan(data_pelanggan.pekerjaan);
                                    setNoTelpon(data_pelanggan.no_telpon);

                                    if (data_pelanggan.status === "1") {
                                        setStatus(true);
                                        setLabelStatus("Aktif");
                                    }
                                    else {
                                        setStatus(false);
                                        setLabelStatus("Nonaktif");
                                    }

                                    setTampilDialog(true);
                                }}
                            >
                                <Card>
                                    <List>
                                        <ListItem button alignItems="flex-start">
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1" noWrap>{data_pelanggan.nama}</Typography>
                                                }
                                                secondary={
                                                    <div>
                                                        <Typography variant="subtitle1" noWrap>{data_pelanggan.pekerjaan}</Typography>
                                                        <Typography variant="body2" noWrap>{data_pelanggan.no_telpon}</Typography>
                                                    </div>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Pelanggan</DialogTitle>

                <DialogContent>
                    <DialogContentText>

                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Nama
                                    </Typography>
                                </Grid>

                                <Grid item xs={8} sm={8} md={9}>
                                    <TextField
                                        id="nama"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        NIK
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <TextField
                                        id="nik"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={nik}
                                        onChange={(event) => setNik(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Alamat
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <TextField
                                        id="alamat"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={alamat}
                                        onChange={(event) => setAlamat(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Pekerjaan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <TextField
                                        id="pekerjaan"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={pekerjaan}
                                        onChange={(event) => setPekerjaan(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        No. Telpon
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <TextField
                                        id="no_telpon"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={no_telpon}
                                        onChange={(event) => setNoTelpon(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={9}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>{id_pelanggan === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Pelanggan);