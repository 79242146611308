import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

//komponen
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

//gambar
import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentsIcon from '@mui/icons-material/Payments';
import HistoryIcon from '@mui/icons-material/History';
import MoneyIcon from '@mui/icons-material/Money';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// form
import Beranda from './beranda';
import Pembayaran from './pembayaran';
import Piutang from './piutang';
import RiwayatTransaksi from './riwayat_transaksi';
import RiwayatAkuntansi from './riwayat_akuntansi';
import PindahSaldo from './pindah_saldo';
import Pengaturan from './pengaturan';

function Utama(props) {
    //state
    const [pilih_menu, setPilihMenu] = useState("beranda");
    const [tampil_dialog_keluar, setTampilDialogKeluar] = useState(false);

    if (!localStorage.getItem("login")) {
        return <Navigate to="/login" />;
    }
    else {
        return (
            <div>
                <Dialog
                    open={tampil_dialog_keluar}
                    onClose={() => setTampilDialogKeluar(false)}
                >
                    <DialogTitle>
                        Konfirmasi
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Apakah anda yakin ingin keluar dari aplikasi?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setTampilDialogKeluar(false)} autoFocus>Tidak</Button>
                        <Button
                            onClick={() => {
                                setTampilDialogKeluar(false);
                                props.proses_keluar();
                            }}
                        >Ya</Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{ backgroundColor: "#eff0f0", height: "100vh" }}>
                    <Box sx={{ pb: 7 }}>
                        <Box
                            sx={pilih_menu === "beranda" ? { width: "100%" } : { display: "none" }}
                        >
                            <Beranda aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Box
                            sx={pilih_menu === "pembayaran" ? { width: "100%" } : { display: "none" }}
                        >
                            <Pembayaran aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Box
                            sx={pilih_menu === "piutang" ? { width: "100%" } : { display: "none" }}
                        >
                            <Piutang aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Box
                            sx={pilih_menu === "riwayat_transaksi" ? { width: "100%" } : { display: "none" }}
                        >
                            <RiwayatTransaksi aplikasi={props.aplikasi} />
                        </Box>

                        <Box
                            sx={pilih_menu === "riwayat_akuntansi" ? { width: "100%" } : { display: "none" }}
                        >
                            <RiwayatAkuntansi aplikasi={props.aplikasi} />
                        </Box>

                        <Box
                            sx={pilih_menu === "pindah saldo" ? { width: "100%" } : { display: "none" }}
                        >
                            <PindahSaldo aplikasi={props.aplikasi} />
                        </Box>

                        <Box
                            sx={pilih_menu === "pengaturan" ? { width: "100%" } : { display: "none" }}
                        >
                            <Pengaturan aplikasi={props.aplikasi} file={props.file} />
                        </Box>

                        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                            <BottomNavigation
                                showLabels
                                sx={{
                                    backgroundColor: "#95BDFF",
                                    overflowX: "auto"
                                }}
                                value={pilih_menu}
                                onChange={(event, newValue) => {
                                    if (newValue === "keluar") {
                                        setTampilDialogKeluar(true);
                                        return;
                                    }
                                    setPilihMenu(newValue);
                                }}
                            >
                                <BottomNavigationAction
                                    value="beranda"
                                    label="Beranda"
                                    icon={<HomeIcon />}
                                    sx={{ color: "white" }}
                                />
                                <BottomNavigationAction
                                    value="pembayaran"
                                    label="Order & Bayar"
                                    icon={<AttachMoneyIcon />}
                                    sx={{ color: "white" }}
                                />

                                <BottomNavigationAction
                                    value="piutang"
                                    label="Piutang"
                                    icon={<PaymentsIcon />}
                                    sx={{ color: "white" }}
                                />
                                <BottomNavigationAction
                                    value="riwayat_transaksi"
                                    label="Riwayat Transaksi"
                                    icon={<HistoryIcon />}
                                    sx={{ color: "white" }}
                                />
                                <BottomNavigationAction
                                    value="riwayat_akuntansi"
                                    label="Riwayat Akuntansi"
                                    icon={<HistoryIcon />}
                                    sx={{ color: "white" }}
                                />
                                <BottomNavigationAction
                                    value="pindah saldo"
                                    label="Pindah Saldo"
                                    icon={<MoneyIcon />}
                                    sx={{ color: "white" }}
                                />
                                <BottomNavigationAction
                                    value="pengaturan"
                                    label="Pengaturan"
                                    icon={<SettingsIcon />}
                                    sx={{ color: "white" }}
                                />
                                <BottomNavigationAction
                                    value="keluar"
                                    label="Keluar"
                                    icon={<ExitToAppIcon />}
                                    sx={{ color: "white" }}
                                />
                            </BottomNavigation>
                        </Paper>
                    </Box>
                </Box>
            </div>
        )
    }
}

export default Utama;