//utama
import React, { useState, forwardRef } from 'react';
import { Navigate } from 'react-router-dom';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

//gambar
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Login(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [tampil_password, setTampilPassword] = useState(false);
    
    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const login = () => {
        if (username.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Username wajib diisi");
            setTampilNotif(true);
            document.getElementById("username").focus();
            return;
        }

        if (password.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Password wajib diisi");
            setTampilNotif(true);
            document.getElementById("password").focus();
            return;
        }

        setProses(true);
        var status_opening = "0";
        fetch(props.aplikasi + '/pengaturan/tampil_pengaturan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    status_opening = data.data[0].status_opening;

                    fetch(props.aplikasi + '/login/login.php',
                        {
                            method: 'POST',
                            body: JSON.stringify
                                ({
                                    username: username,
                                    password: password
                                }),
                            headers:
                            {
                                "Content-type": "application/json; charset=UTF-8"
                            }
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data.kode === 1) {

                                if (status_opening === "0" && data.data[0].level !== "A") {
                                    setJenisNotif("warning");
                                    setIsiNotif("Fasilitas ditutup. Hubungi Administrator");
                                    setTampilNotif(true);
                                    return;
                                }

                                localStorage.setItem("login", "T");
                                localStorage.setItem("id_pengguna", data.data[0].id_pengguna);
                                localStorage.setItem("nama", data.data[0].nama);
                                localStorage.setItem("username", data.data[0].username);
                                localStorage.setItem("level", data.data[0].level);
                                localStorage.setItem("foto", data.data[0].foto);
                                localStorage.setItem("menu", "Beranda");
                                localStorage.setItem("submenu_laporan", false);
                                localStorage.setItem("submenu_akuntansi", false);
                                localStorage.setItem("submenu_transaksi", false);
                                localStorage.setItem("submenu_master", false);

                                setJenisNotif("success");
                                setIsiNotif(data.pesan);
                                setTampilNotif(true);
                                setProses(false);
                            }
                            else {
                                setJenisNotif("warning");
                                setIsiNotif(data.pesan);
                                setTampilNotif(true);
                                document.getElementById("username").focus();
                                setProses(false);
                            }
                        })
                        .catch(error => {
                            setJenisNotif("error");
                            setIsiNotif("Terjadi kesalahan");
                            setTampilNotif(true);
                            document.getElementById("username").focus();
                            setProses(false);
                        }
                        );
                }
                else {
                    status_opening = "0";
                }
            })
            .catch(error => {
                status_opening = "0";
            }
            );
    }

    if (localStorage.getItem("login")) {
        return <Navigate to="/" />;
    }
    else {
        return (
            <div>
                <Snackbar
                    open={tampil_notif}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={tutup_notif}
                >
                    <Alert
                        severity={jenis_notif}
                        variant="filled"
                        onClose={tutup_notif}
                        sx={{ width: '100%' }}
                    >
                        {isi_notif}
                    </Alert>
                </Snackbar>

                <Box
                    sx={{
                        backgroundImage: "url(" + props.file + "/Foto/Aplikasi/wallpaper.jpg)",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        width: "100vw",
                        height: "100vh",
                        position: "fixed"
                    }}
                >
                </Box>

                <Paper
                    sx={{
                        zIndex: "1",
                        backgroundColor: "rgba(255,255,255,0.75)",
                        padding: "10px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        overflow: "auto",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <center>
                        <Avatar
                            src={props.file + "/Foto/Aplikasi/logo.png"}
                            sx={{ width: 128, height: 128 }}
                        />
                    </center>

                    <Grid container item spacing={1}
                        sx={{
                            padding: "10px"
                        }}
                    >
                        <Grid container item>
                            <FormControl variant="outlined" sx={{ width: "100%" }}>
                                <InputLabel htmlFor="username">Username</InputLabel>
                                <OutlinedInput
                                    id="username"
                                    fullWidth
                                    type="text"
                                    value={username}
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            document.getElementById("password").focus();
                                        }
                                    }}
                                    onChange={(event) => setUsername(event.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                <AccountCircle sx={{ color: "#219cd9" }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </Grid>

                        <Grid container item>
                            <FormControl variant="outlined" sx={{ width: "100%" }}>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    fullWidth
                                    type={tampil_password ? "text" : "password"}
                                    value={password}
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            login();
                                        }
                                    }}
                                    onChange={(event) => setPassword(event.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={(event) => setTampilPassword(!tampil_password)}
                                                onMouseDown={(event) => event.preventDefault()}
                                                edge="end"
                                            >
                                                {tampil_password ? <VisibilityOff sx={{ color: "#219cd9" }} /> : <Visibility sx={{ color: "#219cd9" }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <center>
                        <LoadingButton
                            loadingPosition="start"
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<LockOutlinedIcon />}
                            sx={{
                                marginBottom: "5px",
                                marginTop: "5px",
                                borderRadius: "5em"
                            }}
                            loading={proses}
                            onClick={() => login()}
                        >
                            Login
                        </LoadingButton>

                        <Typography variant="subtitle1">
                            Powered by VSA Consulting
                        </Typography>

                        <Typography variant="subtitle2">
                            consultingvsa1@gmail.com
                        </Typography>
                    </center>
                </Paper>
            </div>
        );
    }
}

export default forwardRef(Login);